.nestingOrderCard {
    height: 100%;
    width: 100%;
    margin-right: 5px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 4px 1px;
    transition-duration: 0.3s;
    border: 1px solid rgb(207, 207, 207);
    cursor: pointer;
}

.selectedNestingOrderCard {
    transition-duration: 0.3s;
    border: 1px solid #1890ff !important;
}

.nestingOrderCard > .ant-card-body {
    padding: 8px;
    height: 100%;
}
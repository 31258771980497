.buttonText {
    font-weight: 600;
    font-size: 13px;
}

.adminManagementTableButton {
    border-radius: 20px;
    margin-left: 10px;
}

.menuDivider {
    margin-top: 0;
    margin-bottom: 0;
}

.employeeEditMenu {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 160px;
    border-radius: 3px;
    overflow: hidden;
}

.employeeEditMenu > .ant-dropdown-menu-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 26px;
    display: flex;
    align-items: center;
}

.employeeEditMenu > .ant-dropdown-menu-item-active {
    background-color: #1890ff;
    color: white;
}

.menuDeleteButton {
    color: #ff4d4f;
}

.menuDeleteButton:hover {
    color: white;
    background-color: #ff4d4f;
}

.activeAdminCounter {
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    padding-right: 19px;
    border-right: 1px dotted rgba(0, 0, 0, 0.22);
    height: 32px;
    display: flex;
    align-items: center;
}

.editAdminModal {

}

.adminTooltip {
    padding-bottom: 3px;
    width: 350px;
    max-width: 350px;
    line-height: 1.2;
    padding-top: 0;
    padding-left: 10px;
}

.adminTooltip .ant-tooltip-arrow {
    left: 285px;
}

.adminTooltip .ant-tooltip-arrow-content {
    background: #1890ff !important;
    bottom: 14px;
    margin-top: -4px;
}

.adminTooltip > .ant-tooltip-content > .ant-tooltip-inner {
    padding: 5px 7px;
    min-height: 22px;
    width: 300px !important;
    border-radius: 5px;
    background: #1890ff !important;
}

.permissionCheckboxes.ant-form-item-has-error > .ant-form-item-control {
    max-height: 64px !important;
    height: 64px !important;
}

.permissionCheckboxes.ant-form-item-has-success > .ant-form-item-control {
    max-height: 44px !important;
    height: 44px !important;
}

.permissionCheckboxes > .ant-form-item-control > .ant-form-item-explain {
    height: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
}

.permissionCheckboxes > .ant-form-item-control > .ant-form-item-control-input {
    min-height: 44px !important;
}

.ant-form-item-has-error > .ant-form-item-control {
    max-height: 52px !important;
    height: 52px !important;
}

.ant-form-item-has-success > .ant-form-item-control {
    max-height: 32px !important;
    height: 32px !important;
}

.ant-form-item {
    transition: none !important;
}

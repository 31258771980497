.text14-600 {
    color: #333f48;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
}

.text14-400 {
    color: #333f48;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
}

.text14-500 {
    color: #333f48;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
}

.text12-500 {
    color: #333f48;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
}

.text12-600 {
    color: #333f48;
    font-family: "Montserrat",sans-serif;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.text12-600-colorless {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.text12-400 {
    color: #333f48;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
}

.text12-500-12 {
    color: #333f48;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
}

.or-style {
    top: 50%;
    color: #9c9c9c;
    right: 0;
    padding: 4px;
    position: absolute;
    font-size: 14px;
    transform: translateX(50%) translateY(-50%);
    font-weight: 600;
    background-color: #f7f8f9;
    box-sizing: inherit;
    display: block;
}

.drop-button {
    border: 1px dashed rgb(189, 189, 189);
    cursor: pointer;
    background: rgba(255,255,255,0.45);
    padding: 0;
    border-radius: 8px;
    width: 100%;
    display: flex;
}

.jss163 {
    justify-content: space-between;
}

.upload-button-side {
    display: flex;
    padding: 16px 20px;
    flex-grow: 0;
    min-width: 0px;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
}

.upload-button-side-left {
    position: relative;
    border-right: 1px dashed rgb(189, 189, 189);
}

.file-icon {
    color: rgb(0, 114, 206);
    width: 32px;
    height: 38px;
    margin-right: 24px;
}

.drop-file-icon {
    color: white;
    width: 64px;
    height: 76px;
}


.item-head {
    width: 100%;
    display: flex;
    border-radius: 5px;
    padding: 8px;
    box-sizing: border-box;
    min-height: 48px;
    background: #07223d;
}

.manualItem-head {
    width: 100%;
    display: flex;
    padding: 8px;
    box-sizing: border-box;
    min-height: 48px;
    background: #FDDB75;
}

.item-error-head {
    display: flex;
    border-radius: 5px 5px 0 0;
    padding: 8px;
    box-sizing: border-box;
    min-height: 48px;
    background: #f9423a;
}

.item-head-left-side {
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    min-width: 0;
    align-items: center;
}

.item-head-right-side {
    display: flex;
    flex-grow: 0;
    align-items: center;
    flex-shrink: 0;
    margin-left: 16px;
    margin-right: 5px;
}

.item-head-checkbox {
    margin-left: 8px;
}

.manual-item-head-checkbox {
    margin-left: 8px;
}

.manual-item-head-checkbox > .ant-checkbox > .ant-checkbox-inner {
    border-color: #07223d;
}

.manual-item-head-checkbox > .ant-checkbox:hover > .ant-checkbox-inner {
    border-color: #1890ff;
}

.item-head-spin {
    margin-left: 8px;
    margin-top: 2px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: #066fc1;
    z-index: 4;
}

.dropOnOverlay {
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 5px;
}

.container:hover .overlay {
    opacity: 0.7;
}

.container {
    cursor: pointer;
}

.overlay {
    border-radius: 4px;
}

.overlay-text {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay-text-3D {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
}

.overlay-text-view {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
}

.delete-tooltip > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
    background: rgba(0,0,0,0) !important;
}

.delete-tooltip {
    padding-bottom: 3px;
}

.delete-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
    padding: 2px 6px;
    min-height: 22px;
}

.thickness-tooltip > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
    background: rgba(0,0,0,0) !important;
}

.thickness-tooltip {
    padding-bottom: 3px;
    max-width: 300px;
    line-height: 1.2;
}

.thickness-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
    padding: 2px 6px;
    min-height: 22px;
    width: 300px !important;
}

.name-tooltip > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
    background: rgba(0,0,0,0) !important;
}

.name-tooltip {
    padding-bottom: 3px;
    width: 350px;
    max-width: 350px;
    line-height: 1.2;
    padding-top: 0;
}

.name-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
    padding: 2px 6px;
    min-height: 22px;
    width: 300px !important;
}

.upload-button > .ant-upload {
    width: 100%;
}

.quantity-field > .ant-col > .ant-form-item-explain {
    min-height: 0;
}

.item-number-input > .ant-input-number-input-wrap > .ant-input-number-input {
    color: #333f48;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.collapse-head > .ant-collapse-header {
    padding: 0 !important;
}

.collapse-head {
    border-radius: 5px !important;
    border: 1px solid #07223d !important;
    background: #07223d !important;
}

.collapse {
    border-radius: 5px;
    margin-bottom: 20px;
    background: #07223d;
    border: none;
}

.collapse > .ant-collapse-item-active {
    background: white;
}
.collapse-head > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

/*----------------------------------*/

.collapseManual-head > .ant-collapse-header {
    padding: 0 !important;
}

.collapseManual-head {
    border-radius: 5px !important;
    border: 1px solid #ffc107 !important;
    background: #FDDB75 !important;
}

.collapseManual {
    border-radius: 5px;
    margin-bottom: 20px;
    background: #FDDB75;
    border: none;
}

.collapseManual > .ant-collapse-item-active {
    background: white;
}
.collapseManual-head > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

/*----------------------------------*/

.ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
}

.orderDivider {
    border-top: 1px dotted #aaaaaa;
}

.progress > .ant-progress-outer > .ant-progress-inner  {
    border-radius: 0 !important;
}

.progress > .ant-progress-outer > .ant-progress-inner > .ant-progress-bg {
    background: #2293ff;
    border-radius: 0 !important;
}

.progress > .ant-progress-outer > .ant-progress-inner > .ant-progress-bg:before {
    animation: 1.5s ant-progress-active 3.2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    background: linear-gradient(to right, rgba(0, 16, 28, 1) 0%, #01335b 100%);;
}

.nextStepButtonText {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
}

.placeOrderButton {
    background: #52c41a;
    border-color: #52c41a;
}

.placeOrderButton:focus {
    background: #73d13d !important;
    border-color: #73d13d !important;
}

.placeOrderButton:hover {
    background-color: #73d13d !important;
    border-color: #73d13d!important;
}

.placeOrderButtonDisabled:hover {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.placeOrderButtonDisabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.processingProgress > .ant-progress-outer > .ant-progress-inner {
    background-color: #bdbdbd;
}

.addDrawingText {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
}

.documentUploadSpin .ant-spin-container {
    height: 100%;
}

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.drawingIcon {
    position: relative;
    top: 4px;
}

.priceText {
    font-size: 12px;
    font-weight: 600;
}

.ant-dropdown-placement-topCenter {
    position: fixed;
}

.materialMultiSelect > .ant-select-selector > .ant-select-selection-placeholder {
    font-family: "Montserrat",sans-serif !important;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 30px;
}

.thicknessMultiSelect > .ant-select-selector > .ant-select-selection-placeholder {
    font-family: "Montserrat",sans-serif !important;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 30px;
}

.multiNoteText {
    color: #eed202;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    background-color: #082745;
    padding: 3px 5px 3px 5px;
    border-radius: 3px;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 5px 5px 3px 3px !important;
}

.errorList > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items .ant-list-item:last-child {
   border-radius: 0 0 4px 4px;
}

.quantity-field > .ant-form-item-control > .ant-form-item-explain {
    display: none;
}

.fetchingOrderSpin > div > .ant-spin {
    max-height: 100vh;
}


.upload-button-big .ant-upload {
    width: 100%;
    height: 240px !important;
}

.upload-button-big .ant-upload {
    height: 100%;
    display: flex;
}

.upload-button-big .upload-button-side-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.upload-button-big .jss163 {
    flex-direction: column;
    justify-content: center;
}

.upload-button-big .buttonRightSide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-button-big .file-icon {
    margin: 4px 0 0;
}

.upload-button-big .anticon {
    margin-right: 6px;
    margin-top: 12px;
}

.guidelinesCard {
    background-color: rgba(255, 255, 255, 0.6);
    border: 3px solid #0b89ff;
    border-radius: 7px;
    padding: 10px;
}

.upload-button-big .text14-600 {
    font-size: 16px;
}

.upload-button-big .text12-500 {
    font-size: 14px;
}

.upload-button-big  .file-icon {
    width: 48px;
    height: 57px;
}

.notAvailable .ant-select-selection-item {
    color: #ffc107;
}

.itemCardWrapper {
    border-radius: 5px 5px 3px 3px;
    margin-bottom: 15px;
    border: 1px solid #07223d;
    overflow: hidden;
}

.manualItemCardWrapper {
    border-radius: 5px 5px 3px 3px;
    margin-bottom: 15px;
    border: 1px solid #ffc107;
    overflow: hidden;
}

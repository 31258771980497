.exportPdfButton {
    margin-right: 5px;
    background-color:#e66a4c;
    border-color: #e66a4c;
    font-size: 13px;
    font-weight: 500;
}

.exportPdfButton:focus {
    background-color: #f57152 !important;
    border-color: #f57152 !important;
}

.exportPdfButton:hover:not(.editingDisabled){
    background-color: #f57152 !important;
    border-color: #f57152 !important;
}

.centeredCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

@media print {
    thead {
        display: table-row-group;
    }
}

@media print {
    .ant-table-thead > tr > .ant-table-cell{
        background-color: #e7e7e7 !important;
        border-bottom: 1px solid #b7b7b7 !important;
    }
}

@media print {
    .avoid { margin: 2px 0 1px 0 }
}

.page-header, .page-header-space {
    height: 30px;
}

.page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
}

.page {
    page-break-after: always;
}

@media print {
    thead {display: table-header-group;}
    tfoot {display: table-footer-group;}
}

.logoText {
    font-size: 28px;
    font-weight: 600;
    color: black;
    font-family: "Montserrat",sans-serif;
    line-height: 1.5;
}

.orderNoteTitle {
    font-size: 32px;
    font-weight: 600;
    color: rgba(53, 59, 72, 0.9);
    font-family: "Montserrat",sans-serif;
    text-align: right;
}

.headerTextFields {
    font-size: 17px;
    font-weight: 600;
    color: rgba(53, 59, 72, 0.9);
    font-family: "Montserrat",sans-serif;
    text-align: right;
}

.headerTextValues {
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    color: rgba(53, 59, 72, 0.9);
    font-family: "Montserrat",sans-serif;
    text-align: left;
    margin-left: 12px;
    border: 1px solid #9a9a9a;
    padding-left: 6px;
}

.orderNoteColumnTitle {
    font-weight: 600;
    color: black;
    font-family: "Montserrat",sans-serif;
}

.orderNoteValues {
    font-size: 13px;
    font-weight: 500;
    color: rgba(53, 59, 72, 0.9);
    font-family: "Montserrat",sans-serif;
}

.darkerColumn {
    background-color: #fafafa;
}

.dataRow {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0 30px 0 30px;
}

.dataTitle {
    font-size: 17px;
    font-weight: 600;
    color: rgba(103, 135, 208, 0.9);
    font-family: "Montserrat",sans-serif;
    margin-bottom: 12px;
}

.invoiceField {
    font-size: 16px;
    font-weight: 600;
    color: rgba(53, 59, 72, 0.9);
    font-family: "Montserrat",sans-serif;
    margin-bottom: 6px;
}


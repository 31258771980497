.deleteMenuItem {
    color: red;
}

.deleteMenuItem:hover {
    color: white;
}

.customerUpdateForm > .infoBox > .detailEntry > .ant-form-item {
    margin: 0;
}

.customerUpdateForm > .infoBox > .inlineField > .customerUpdateFormItemContainer > .ant-form-item {
    margin: 0;
}

.customerUpdateForm > .infoBox > .updateButton > .ant-form-item {
    margin: 0;
}

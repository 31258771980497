.orderDetailsTitle {
    font-size: 20px;
    font-weight: 600;
    color: #333f48;
    margin-right: 20px;
}

.dataValue {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.dataValue2 {
    font-size: 14px;
    font-weight: 500;
}

.quotedItemsList {
    padding: 6px;
    overflow: auto;
    background-color: rgb(236, 236, 236);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}

.quotedItemsList::-webkit-scrollbar {
    border-radius: 0 4px 4px 0;
}

.quotedItemsList::-webkit-scrollbar-track {
    border-radius: 0 4px 4px 0;
}

.orderDetailsDatePicker {
    font-size: 13px !important;
    font-weight: 600;
    height: 30px !important;
}

.react-datepicker__day--outside-month {
    color: #ccc !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: rgba(0,0,0,0) !important;
    border: none !important;
}

.react-datepicker__navigation {
    top: 10px !important;
}

.react-datepicker__day--outside-month > span {
    color: #c4c4c4;
}

.declinedCard {
    background: repeating-linear-gradient(
            45deg,
            #ffffff,
            #ffffff 10px,
            #f8f8f8 10px,
            #f8f8f8 20px
    );
    opacity: 0.5;
}

.changedCard {
    background-color: #e3fde3;
}

.editedCard {
    background-color: #f8fff8;
}

.inProgressButton {
    background: #1890ff;
    border-color: #1890ff
}

.inProgressButton:focus {
    background: #40a9ff !important;
    border-color: #40a9ff !important;
}

.inProgressButton:hover{
    background-color: #40a9ff !important;
    border-color: #40a9ff !important;
}

.small-tooltip {
    padding-bottom: 2px;
}

.small-tooltip > .ant-tooltip-content > .ant-tooltip-arrow > .ant-tooltip-arrow-content {
    background: rgba(0,0,0,0) !important;
}

.small-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
    padding: 2px 6px;
    min-height: 22px;
}

.textAreaInput {
    height: 100% !important;
    max-height: none !important;
    width: 100%;
}

.textAreaInput.ant-form-item-has-success {
    height: 100% !important;
    max-height: none !important;
}

.textAreaInput > .ant-form-item-control {
    max-height: inherit !important;
}

.textAreaInput.ant-form-item-has-success > .ant-form-item-control {
    height: 100% !important;
    max-height: inherit !important;
}

.textAreaInputText::after {
    font-size: 13px;
    font-weight: 600;
}

.editQuantityButton {
    color: #1890ff;
    margin-left: 4px;
    cursor: pointer;
}

.editQuant .ant-input-number-handler-wrap {
    display: none;
}

#producedQuantityForm {
    display: inline-block;
    height: 52px;
}

#producedQuantityForm > .ant-form-item {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    bottom: 4px;
    margin-left: 4px;
}

#producedQuantityForm > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-number {
    margin-left: 0 !important;
}
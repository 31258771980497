
.accountInfoForm .ant-form-item {
    display: flex;
    flex-direction: column;
}

.accountInfoForm .ant-form-item-label {
    display: flex;
    justify-content: flex-start;
}

.passwordForm .ant-form-item {
    display: flex;
    flex-direction: column;
}

.passwordForm .ant-form-item-label {
    display: flex;
    justify-content: flex-start;
}


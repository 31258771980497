
.producerDetailsContainer {
    display: flex;
    flex-direction: row;
}

.infoBox {
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px;
    width: calc(100% - 24px);
    margin: 12px;
    flex-direction: column;
}

.detailEntry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 100%;
    padding-right: 12px;
}

.detailEntry > .ant-input-disabled {
    width: 100%;
    background: whitesmoke;
    color: inherit;
}

.detailEntry > .ant-input-disabled:hover {
    cursor: default;
}

.inlineDetailEntry {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 12px;
    margin-bottom: 12px;
}

.inlineField {
    display: flex;
    align-items: center;
}

.inputClass > .ant-input-number {
    width: 74px;
    background: whitesmoke;
    cursor: default !important;
}

.inputClass > .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    cursor: default;
}

.inputClass > .ant-input-number-disabled > .ant-input-number-input-wrap > .ant-input-number-input {
    color: rgba(0, 0, 0, 0.86) !important;
}

.totalEarnings > .ant-input-number {
    width: 100%;
}

.trueDisabledCheckbox > .ant-checkbox-wrapper {
    cursor: default !important;
}

.trueDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled > .ant-checkbox-input {
    cursor: default !important;
}

.trueDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff !important;
}

.trueDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: white;
}

.falseDisabledCheckbox > .ant-checkbox-wrapper {
    cursor: default !important;
}

.falseDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled > .ant-checkbox-input {
    cursor: default !important;
}

.falseDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: whitesmoke;
}

.falseDisabledCheckbox > .ant-checkbox-wrapper > .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: white;
}



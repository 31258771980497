.drawingSpinWrapper {
    height: 100%;
    width: 75%;
}

.drawingSpinWrapper > div > .ant-spin {
    height: 100%;
    max-height: 100vh;
    width: 100%;
}

.drawingSpinWrapper .ant-spin-container {
    height: 100%;
}

.detailsSpinWrapper {
    height: calc(100% - 40px);
    width: 25%;
    min-width: 400px;
    margin-top: 40px;
}

.detailsSpinWrapper > div > .ant-spin {
    height: 100%;
    max-height: 100vh;
    width: 100%;
}

.detailsSpinWrapper .ant-spin-container {
    height: 100%;
}

#plateDrawing > svg {
    top:0; left:0; height: calc(100vh - 150px); width:100%
}

#plateDrawing > svg > #figure_1 > #axes_1 > g:not(:first-child) {
    width: 100%;
    stroke: #000000;
    stroke-width: 0.15px;
}

figure {
    height: 100%;
    display: block;
    width: 100%;
}

#marginDrawing > svg {
    top:0; left:0; height: calc(100vh - 150px); width:100%
}

.nestedPartName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 190px;
}
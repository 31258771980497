.button-home{
    bottom:100px;
    left:25px;
    position: relative;
    width: 35px;
    opacity: 0;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    -webkit-transition: 200ms;
    -webkit-transition-timing-function: ease-in-out;
}
.cube-view-container:hover .button-home{
    opacity: 1;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    -webkit-transition: 200ms;
    -webkit-transition-timing-function: ease-in-out;
}

.button-home:hover {
    cursor: pointer;
}

#cad-view > canvas {
    border: 2px solid #1890ff;
}

.warningWindow > .ant-modal-content{
    border: 2px solid #ff475d;
}

/*[data-tooltip] {*/
/*    display: inline-block;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    padding: 4px;*/
/*}*/
/*!* Tooltip styling *!*/
/*[data-tooltip]:before {*/
/*    content: attr(data-tooltip);*/
/*    display: none;*/
/*    position: absolute;*/
/*    background: #1579d9;*/
/*    color: #fff;*/
/*    padding: 4px 8px;*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*    line-height: 1.4;*/
/*    min-width: 100px;*/
/*    text-align: center;*/
/*    border-radius: 4px;*/
/*}*/
/*!* Dynamic horizontal centering *!*/
/*[data-tooltip-position="top"]:before,*/
/*[data-tooltip-position="bottom"]:before {*/
/*    left: 50%;*/
/*    -ms-transform: translateX(-50%);*/
/*    -moz-transform: translateX(-50%);*/
/*    -webkit-transform: translateX(-50%);*/
/*    transform: translateX(-50%);*/
/*}*/
/*!* Dynamic vertical centering *!*/
/*[data-tooltip-position="right"]:before,*/
/*[data-tooltip-position="left"]:before {*/
/*    top: 50%;*/
/*    -ms-transform: translateY(-50%);*/
/*    -moz-transform: translateY(-50%);*/
/*    -webkit-transform: translateY(-50%);*/
/*    transform: translateY(-50%);*/
/*}*/
/*[data-tooltip-position="top"]:before {*/
/*    bottom: 100%;*/
/*    margin-bottom: 6px;*/
/*}*/
/*[data-tooltip-position="right"]:before {*/
/*    left: 100%;*/
/*    margin-left: 6px;*/
/*}*/
/*[data-tooltip-position="bottom"]:before {*/
/*    top: 100%;*/
/*    margin-top: 6px;*/
/*}*/
/*[data-tooltip-position="left"]:before {*/
/*    right: 100%;*/
/*    margin-right: 6px;*/
/*}*/

/*!* Tooltip arrow styling/placement *!*/
/*[data-tooltip]:after {*/
/*    content: '';*/
/*    display: none;*/
/*    position: absolute;*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-color: transparent;*/
/*    border-style: solid;*/
/*}*/
/*!* Dynamic horizontal centering for the tooltip *!*/
/*[data-tooltip-position="top"]:after,*/
/*[data-tooltip-position="bottom"]:after {*/
/*    left: 50%;*/
/*    margin-left: -6px;*/
/*}*/
/*!* Dynamic vertical centering for the tooltip *!*/
/*[data-tooltip-position="right"]:after,*/
/*[data-tooltip-position="left"]:after {*/
/*    top: 50%;*/
/*    margin-top: -6px;*/
/*}*/
/*[data-tooltip-position="top"]:after {*/
/*    bottom: 100%;*/
/*    border-width: 6px 6px 0;*/
/*    border-top-color: #1579d9;*/
/*}*/
/*[data-tooltip-position="right"]:after {*/
/*    left: 100%;*/
/*    border-width: 6px 6px 6px 0;*/
/*    border-right-color: #1579d9;*/
/*}*/
/*[data-tooltip-position="bottom"]:after {*/
/*    top: 100%;*/
/*    border-width: 0 6px 6px;*/
/*    border-bottom-color: #1579d9;*/
/*}*/
/*[data-tooltip-position="left"]:after {*/
/*    right: 100%;*/
/*    border-width: 6px 0 6px 6px;*/
/*    border-left-color: #1579d9;*/
/*}*/
/*!* Show the tooltip when hovering *!*/
/*[data-tooltip]:hover:before,*/
/*[data-tooltip]:hover:after {*/
/*    display: block;*/
/*    z-index: 50;*/
/*}*/

.react-datepicker__day--outside-month {
    pointer-events: none;
}

.react-datepicker__day--disabled {
    pointer-events: none;
}

.react-datepicker__day--outside-month > span {
    color: #c4c4c4;
}

.react-datepicker__day--selected {
    background-color: #efefef;
    border: 2px #1579d9 solid;
    color: black
}

.react-datepicker__day--selected:hover {
    background-color: #d7d7d7;
}

.react-datepicker__close-icon:hover::after {
    background-color: #5c5c5c;
    transition: all 0.3s;
}

.react-datepicker__close-icon::after {
    background-color: #8e8e8e;
    transition: all 0.3s
}

.react-datepicker__day--keyboard-selected {
    background-color: #efefef;
    border: 1px #1579d9 solid;
    color: black
}

.react-datepicker__day--keyboard-selected:hover {
    background-color: #d7d7d7;
}

.react-datepicker__input-container > input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    font-weight: 500 !important;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    outline:none !important;
}

.ant-form-item-has-error .react-datepicker__input-container > input {
    border: 1px solid #ff4d4f;
}

.anchor {
    margin-left: 8px;
    opacity: 0;
    transition: opacity .2s ease;
}

.anchor-title:hover .anchor, .anchor:hover {
    opacity: 1;
}

.ant-anchor-link-title {
    font-size: 14px;
}

::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.45);
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #88888d;
    border: 4px solid #f4f4f4;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 1);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #999a9e;
    border-radius: 14px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display:none;
}

.slider-okvir {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.12);
}

.slider-okvir:hover {
    cursor: default;
}

.whiteModal > .ant-modal-content > .ant-modal-header {
    background: #ffffff;
    transition: all 0.4s
}

.blackModal > .ant-modal-content > .ant-modal-header {
    background: #222222;
    transition: all 0.4s;
    border-bottom: 1px solid #222222;
}

.blackModalCard > .ant-card-body::-webkit-scrollbar-thumb {
    background: #222222;
}

.blackModalCard > .ant-card-body::-webkit-scrollbar-track {
    background: #222222;
    border-radius: 0 5px 5px 0;
}

.whiteModalCard > .ant-card-body::-webkit-scrollbar-track {
    transition: all 0.4s;
    border-radius: 0 5px 5px 0;
}

.whiteModalCard {
    background: #ffffff;
    transition: all 0.4s
}

.blackModalCard {
    background: #222222;
    transition: all 0.4s;
    color: #ffffff;
}

.blackModal > .ant-modal-content > .ant-modal-close {
    color: #ffffff;
}
.blackModal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    color: #ffffff;
}

.ant-slider:hover {
    cursor: grab;
}

.ant-slider:active {
    cursor: all-scroll;
}

.ant-slider-handle:hover {
    cursor: grab;
}
.ant-slider-handle:active {
    cursor: all-scroll;
}

.step-canvas:active {
    cursor: grab;
}

.step-canvas {
    border-radius: 6px !important;
    overflow:hidden;
}

.step-canvas > canvas {
    border-radius: 6px !important;
}

.partView {
    border-radius: 6px !important;
    overflow:hidden;
}

.partView > .ant-modal-content {
    border-radius: 6px !important;
}

.partView > .ant-modal-content > .ant-modal-body {
    border-radius: 6px 6px 6px 6px !important;
    overflow:hidden;
}

.typeGroup {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.12);
}





.laserSpeedZeroRow {
    background-color: #001529;
    border-top: 3px solid #001529 !important;
}

.laserSpeedLeftColumn {
    background-color: #fafafa;
    padding-left: 6px !important;
}

.laserSpeedOddRow {
    background-color: #fafafa;
}

.laserSpeedDisabledRow {
    background-color: #e8e8e8;
    pointer-events: none;
}

.laserSpeedDisabledOddRow {
    background-image: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.03) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0, 0, 0, 0.03) 50%,
            rgba(0, 0, 0, 0.03) 75%,
            transparent 75%,
            transparent
    );
    background-color: #dedede;
    background-size: 10px 10px;
    pointer-events: none;
}

.speedTableCellText {
    padding-top: 2px;
}

.speedTableCellLeft {
    margin-top: 2px;
    text-align: left;
}

.cellText {
    text-align: right;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.laserSpeedTableInput > .ant-input-number-input-wrap > .ant-input-number-input {
    padding: 0;
    margin-right: 5px;
    text-align: right;
    height: 25px;
}

.laserSpeedTableInput > .ant-input-number-handler-wrap {
    display: none;
}


/*.TopRightGrid_ScrollWrapper > .ReactVirtualized__Grid {*/
/*    height: 66px !important;*/
/*    border-radius: 0 5px 0 0;*/
/*}*/

/*.BottomLeftGrid_ScrollWrapper {*/
/*    border-radius: 0 0 0 5px !important;*/
/*}*/

/*.ReactVirtualized__Grid > .ReactVirtualized__Grid__innerScrollContainer {*/
/*    border-radius: 5px 0 0 0;*/
/*}*/

.topLeftGrid {
    font-weight: 600;
    color: #ffffff;
    background-color: #001529;
    border-radius: 5px 0 0 0;
}

.topRightGrid {
    font-weight: 600;
    color: #ffffff;
    background-color: #001529;
    border-radius: 0 5px 0 0;
    height: 200px !important;
    max-height: 200px !important;
}

.bottomLeftGrid {
    border-right: 2px solid #001529;
    background-color: #f7f7f7;
    font-weight: bold;
    border-radius: 0 0 0 5px;
    width: 157px !important;
}

.bottomRightGrid {
    background-color: #fafafa;
    /*background-image: url("/src/assets/images/column.svg");*/
    /*background-attachment: local;*/
    /*background-size: auto 200px;*/
    /*background-repeat: repeat-x;*/
}

.emptyTable {
    max-width: 100%;
    height: 260px;
    border-radius: 5px 5px 0 0;
}

.emptyTableHeader {
    height: 35px;
    width: 100%;
    background-color: #001529;
    border-radius: 5px 5px 0 0;
}

.emptyTableBody {
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 0px 0px 5px 5px;
}

.emptyTableText {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #8e8e8e;
    margin: 0 56px 40px 56px;
}
/*.ant-table-placeholder {*/
/*    height: calc(100vh - 136px) !important;*/
/*}*/

.awaitingConfirmationOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content {
    min-height: calc(100vh - 450px) !important;
}

.awaitingConfirmationOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content > table > .ant-table-tbody > .ant-table-placeholder {
    height: calc(100vh - 490px) !important;
}


.awaitingConfirmationOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-radius: 5px;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
}

.emptyRecentAwaiting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 210px;
    width: 100%;
}
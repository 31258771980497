.ant-menu-submenu-popup {
    z-index: 1101;
}

.ant-menu-inline-collapsed-tooltip {
    z-index: 1101;
}

.collapseButton {
    position: fixed;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #ccc;
    background-color: #02203C !important;
    font-size: 18px;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.25s;
    padding: 0;
    border: none;
}

.collapseButton:hover {
    cursor: pointer;
    color: white;
    background-color: #073763;
}
.collapseButton:focus {
    cursor: pointer;
    color: white;
    background-color: #073763;
}

.menuLine {
    transition: all 0.25s !important;
    margin-left: 8px;
    font-weight: 600;
    font-size: 13px;
}

.subMenuC > .ant-menu-submenu-title {
    padding-left: 22px !important;
    height: 40px !important;
}

.menuItem {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.materialFormItem {
    margin-bottom: 0 !important;
}

.cuttingSpeedText {
    margin-top: 10px;
    margin-bottom: -10px;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 500;
}

.laserDivider {
    margin-bottom: 15px;
    margin-top: 15px;
}

.ant-form-item-label {
    font-size: 13px !important;
    font-weight: 500;
}

.ant-form-item-label > label {
    font-size: 13px !important;
    font-weight: 500;
}

.customColumn2 {
    max-width: 100%;
}

.inLine .customColumn3 {
    max-width: 1000px;
    width: calc(100% - 620px);
    margin-right: 16px;
}

.newLine .customColumn3 {
    width: 100%;
    max-width: 1000px;
}

.column3CardContainer {
    max-width: 1000px !important;
}

.customColumn4 {
    width: 620px;
}



.testClass > .ant-input::-webkit-outer-spin-button,
.testClass > .ant-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.testClass > .ant-input[type=number] {
    -moz-appearance: textfield;
}

.calculationCard .ant-card-head {
    min-height: 32px;
    padding-left: 15px;
    padding-right: 15px;
}

.calculationCard .ant-card-head-title {
    padding-top: 2px;
    padding-bottom: 2px;
}

.cuttingCalculationItem {
    margin-bottom: 10px !important;
}


.modalDataContainer {
    margin-top: 12px;
    padding: 6px;
    overflow: auto;
    height: 560px;
    background-color: rgb(236, 236, 236);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}

.checkboxStyle {
    display: flex;
    align-items: center;
}

.bendCard {
    margin-top: 6px;
    margin-bottom: 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
    border: 1px solid rgb(207, 207, 207);
    width: 100%;
}
.bendCard > .ant-card-body {
    padding: 16px;
}

.multiDeleteCheckbox {
    margin-right: 8px;
}

.dropPanel > .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 0 10px 10px;
}

.ant-select-item-option-content {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.clearOrderButton:hover {
    color: #ff4d4f;
    border-color: #ff4d4f;
}

.archivedOrdersCardContainer {
    transition: all 0.3s ease-in;
}

.archivedOrderElement {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.archivedOrdersSmall {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.archivedOrdersSmall:hover {
    cursor: pointer;
}

.upload-button-bulk > .ant-upload {
    width: 100%;
    height: 200px;
}

.upload-button-bulk > .ant-upload > .ant-upload {
    height: 100%;
    display: flex;
}

.upload-button-bulk > .ant-upload > .ant-upload > .drop-button > .upload-button-side-left {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
}

.upload-button-bulk > .ant-upload > .ant-upload > .drop-button > .jss163 {
    flex-direction: column;
    justify-content: center;
}

.upload-button-bulk > .ant-upload > .ant-upload > .drop-button > .jss163 > .buttonRightSide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-button-bulk .file-icon {
    margin: 4px 0 0;
    width: 48px;
    height: 57px;
}

.upload-button-bulk > .ant-upload > .ant-upload > .drop-button > .jss163 > .anticon {
    margin-right: 6px;
    margin-top: 12px;
}

.upload-button-bulk .text14-600 {
    font-size: 16px;
}

.upload-button-bulk .text12-500 {
    font-size: 14px;
}

.upload-button-bulk .ant-upload {
    width: 100%;
    height: 240px !important;
}

.file-icon-2 {
    width: 27px;
    height: 38px;
    margin-right: 24px;
}

.file-icon-3 {
    width: 27px;
    height: 38px;
    margin: 4px 0 0;
}

.loading {
    display: flex;
    min-width: 24px !important;
    justify-content: center;
    font-size: 20px;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

.nameCheckmark {
    font-size: 24px;
    color: #51b364;
}


.summaryContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
}

.orderSummary {
    display: flex;
    height: 33px;
    align-items: center;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
}

.summaryContent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.archivedOrdersSticky {
    padding-top: 15px
}

.priceInputField {
    margin: 0 !important;
}

.priceInputField {
    max-height: 24px !important;
}

.priceInputField .ant-form-item-control-input {
    min-height: 0 !important;
}

.archiveOrderNameInput {
    width: 100%;
}

.archiveOrderNameInput.ant-form-item-has-error > .ant-form-item-control{
    height: 53px !important;
    max-height: 53px !important;
}

.width80 {
    min-width: 80px;
}

#panel1c-header2.MuiAccordionSummary-root {
    max-height: 64px !important;
}

.archiveSearch {
    max-width: 300px;
    margin: 8px 4px 7px 4px
}

.totalPriceInfo {
    color: #333f48;
    font-size: 14px;
}

.totalPriceInfo:hover {
    color: #0b89ff;
    cursor: pointer;
}

.priceInfoTooltip {
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 6px 4px;
}

.tooltipLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tooltipLine span:nth-of-type(even) {
    margin-left: 10px;
}

.hoverable:hover {
    color: #0b89ff;
    cursor: pointer;
}
.archivedNestingOrderCard {
    height: 100%;
    width: 100%;
    margin-right: 7px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 4px 1px;
    transition-duration: 0.3s;
    border: 1px solid rgb(207, 207, 207);
    cursor: pointer;
    transition: .3s background;
}

.archivedNestingOrderCard:active {
    background: #ddecfa;
    transition: .3s background;
}

.archivedNestingOrderCard:hover {
    border: 1px solid #1890ff !important;
}

.archivedNestingOrderCard:hover .orderName {
    color: #1890ff;
}

.selectedNestingOrderCard {
    transition-duration: 0.3s;
    border: 1px solid #1890ff !important;
}

.archivedNestingOrderCard > .ant-card-body {
    padding: 8px;
    height: 100%;
}

.archivedCalculationsSpinWrapper {
    height: 100%;
}

.archivedCalculationsSpinWrapper > .ant-spin-container {
    height: 30%;
}

.disabledField {
    color: #868686;
}

.disabledField > .ant-form-item > .ant-form-item-label > label {
    color: #868686;
}

.disabledField > .ant-form-item > .ant-form-item-label > label::after {
    color: #868686;
}

.disabledField > .ant-typography {
    color: #868686;
}

.ant-input-number-disabled > .ant-input-number-input-wrap > .ant-input-number-input{
    color: #868686 !important;
}
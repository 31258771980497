
.fetchingArchivedOrderSpin {
    height: 100% !important;
}

.fetchingArchivedOrderSpin > div > .ant-spin {
    max-height: 100vh;
}

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shippingArchivedFormItem {
    margin-bottom: 8px !important;
    height: 32px !important;
}

.shippingArchivedFormItem.ant-form-item-with-help {
    margin-bottom: 8px !important;
}

.shippingArchivedFormItem > .ant-form-item-control {
    height: 32px !important;
}

.dont-show-wrapper {
    display: none;
}
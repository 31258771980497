[data-theme='compact'] .card-container > .ant-tabs-card > .ant-tabs-content,
.card-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
}

[data-theme='compact'] .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane,
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

[data-theme='compact'] .card-container > .ant-tabs-card > .ant-tabs-bar,
.card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

[data-theme='compact'] .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab,
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

[data-theme='compact'] .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active,
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
.card-container {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    height: 100%;
}

.settingsCard {
    position: relative;
    border-top: none;
    margin: 0 10px 0 10px;
}

.settingsTabs > .ant-tabs-nav {
    margin: 0 !important;
}

.settingsTabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    border: 1px solid rgb(205, 205, 205);
    border-bottom: none;
}

.accountSettingsForm > .ant-form-item > .ant-form-item-label {
    width: 144px;
    text-align: end;
}

.accountFormButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ant-input-prefix {
    margin-right: 8px;
}

.nameField {
    width: 100%;
}

.accountSettingsForm > .ant-row > .ant-col > .infoBox > .nameField > .ant-form-item {
    width: 100%;
}

.accountSettingsForm > .ant-row > .ant-col > .infoBox > .ant-form-item {
    width: 100%;
}

.accountSettingsForm > .ant-row > .ant-col > .infoBox > .ant-form-item > .ant-form-item-label {
    width: 144px !important;
}

.unitsRadioFieldInput.ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
    justify-content: flex-start !important;
}
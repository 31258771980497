.dashboardWelcome {
    font-size: 24px;
    font-weight: 600;
    color: #333f48;
}

.newOrderBanner {
    background-color: rgba(255, 255, 255, 0.6);
    border: 3px solid #0b89ff;
    border-radius: 5px;
    display: block;
    padding: 16px;
    cursor: pointer;
    height: 350px;
}

.getQuoteButton {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    border-radius: 3px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.07) 0 2px 4px 1px;
}

.getQuoteText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    font-weight: 600;
    margin-left: 10px;
    width: 100%;
}

.getQuoteLabel {
    top: 25px;
    left: 6px;
    position: absolute;
}

.getQuoteUpper {
    text-align: center;
    font-size: 18px;
    min-width: 0;
    align-items: center;
    font-weight: 600;
    border-bottom: 1px dotted #ffffff;
    padding-bottom: 10px;
    width: 100%;
}

.getQuoteLabel:before {
    left: -4px;
    width: 0;
    border: 4px solid transparent;
    bottom: -4px;
    height: 0;
    content: "";
    display: block;
    position: absolute;
    border-right-color: #5F8DBB;
}

.getQuoteLabelContent {
    color: #333f48;
    padding: 4px 24px;
    z-index: 2;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    border-radius: 3px 3px 3px 0;
    background-color: #CBE5FF;
}

.draftsTitle {
    font-size: 20px;
    font-weight: 600;
    color: #333f48;
}

.draftCard {
    position: relative;
    top: 0;
    transition: top ease 0.4s, box-shadow 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    background-color: white;
    min-height: 127px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.07) 0 2px 4px 1px;
}

.draftCard:hover {
    cursor: pointer;
    top: -4px;
    box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
    -webkit-box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
    -moz-box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
}

.draftCard:hover > .titleWrapper > .draftCardTitle {
    color: #0b89ff;
}

.draftCardTitle {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.4s;
    color: #333f48;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
}

.draftInfo {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
}

.draftText {
    line-height: 1.8;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
}

.draftProgressBar {
    height: 2px;
    width: 100%;
    background-color: #e0e0e0;
}

.emptyDrafts {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 272px;
    width: 1136px;
}

.emptyAwaiting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.emptyDraftsText {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #8e8e8e;
    margin: 0 56px 0 56px;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
}

.draftRemoveButton {
    margin-left: 5px;
}

.draftRemoveButton:hover {
    border-color: #f9423a;
}

.awaitingConfirmationBox {
    margin-top: 12px;
    padding: 6px;
    overflow: auto;
    background-color: rgb(236, 236, 236);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}

.recentConfirmationsBox {
    padding: 6px;
    overflow: auto;
    background-color: rgb(236, 236, 236);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    height: 355px;
}

.awaitingConfirmationLeft {
    font-size: 16px;
    font-weight: 600;
    color: #333f48;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.awaitingConfirmationRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.awaitingConfirmationBox::-webkit-scrollbar {
    border-radius: 0 4px 4px 0;
}

.awaitingConfirmationBox::-webkit-scrollbar-track {
    border-radius: 0 4px 4px 0;
}

.awaitingConfirmationSpin {
    height: calc(100% - 14px);
}

.awaitingConfirmationSpin > .ant-spin-container {
    height: 100%;
}

.awaitingConfirmationSpin > .ant-spin-container > div {
    height: 55%;
}

.recentStatusLeft {
    font-size: 16px;
    font-weight: 600;
    color: #333f48;
    display: flex;
    align-items: center;
    align-self: center;
}

.ellipsisStatusChange {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 20px;
}

.recentCard {
    margin-top: 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
    border: 1px solid rgb(207, 207, 207);
    width: 100%;
}
.recentCard > .ant-card-body {
    padding: 16px;
}

.recentChangesSpin {
    height: 355px;
}



.dashboardTitle {
    font-size: 20px;
    font-weight: 600;
    color: #333f48;
    margin-bottom: 2px;
    margin-left: 5px;
}

.dashboardCard {
    position: relative;
    top: 0;
    transition: top ease 0.4s, box-shadow 0.4s ease-in-out;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    background-color: white;
    min-height: 120px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.07) 0 2px 4px 1px;
}

.dashboardCard:hover {
    cursor: pointer;
    top: -4px;
    box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
    -webkit-box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
    -moz-box-shadow: -1px 10px 14px -4px rgba(0,0,0,0.33);
}

.dashboardCard:hover > .dashboardCardTitleWrapper > .dashboardCardTitle {
    color: #0b89ff !important;
}

.dashboardCardTitle {
    font-size: 18px;
    font-weight: 600;
    cursor: default;
    transition-duration: 0.4s;
    color: #333f48;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
}

.dashboardInfo {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
}

.dashboardText {
    line-height: 1.8;
    font-size: 12px;
    font-weight: 500;
}

.dashboardCardTitleWrapper {
    display: flex;
}

.dashboardContainerBox {
    padding: 6px;
    overflow: hidden;
    background-color: rgb(236, 236, 236);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}
.materialsTabs > .ant-tabs-nav {
    margin: 0 0 0 10px !important;
}

.ant-tabs-tab {
    font-weight: 500;
    font-size: 12px;
}

.ant-tabs-dropdown-menu-item {
    font-weight: 500;
    font-size: 12px;
}

.tabMaterialCard {
    position: relative;
    border-top: none;
    box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.11);
    margin: 0 10px 10px 10px;
}

.tableTabCard {
    box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.11);
}

.tableCardWrapper {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.08);
}

.materialTableXAxis {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: -10px;
    color: #444444;
}

.materialTableYAxis {
    font-weight: 500;
    font-size: 15px;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-220px);
    color: #444444;
}

.laserSpeedCard {
    border-top: none;
    border-radius: 0 5px 5px 5px;
    margin-left: 10px;
}

.laserSpeedTabs > .ant-tabs-nav {
    margin: 0 0 0 10px !important;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.yesSelect {
    -webkit-touch-callout: default; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.inLine2 > .customColumn {
    width: calc(70% - 8px);
}


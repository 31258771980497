
.nestingList {
    box-sizing: border-box;
}
.nestingRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 6px 4px;
    box-sizing: border-box;
}

.nestingRow:nth-of-type(1) {
    padding: 6px 0 6px 4px;
}

.nestingItem {
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nestingPicture {
    width: 190px;
    height: 100%;

    border-radius: 3px;
    border: 1px solid rgb(205, 205, 205);
    background: rgb(241,240,240);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.nestedPlateCard {
    height: 100%;
    width: 100%;
    margin-right: 5px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 4px 1px;
    border: 1px solid rgb(207, 207, 207);
}

.nestedPlateCard > .ant-card-body {
    padding: 12px;
    height: 100%;
}

.fadeInCard {
    animation: fadeIn 0.3s;
    -webkit-animation: fadeIn 0.3s;
    -moz-animation: fadeIn 0.3s;
    -o-animation: fadeIn 0.3s;
    -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.nestingText {
    font-size: 13px;
    margin-bottom: 2px;
}

.nestingText500 {
    font-weight: 500;
}

.nestingCard > .ant-spin-nested-loading {
    height: 100%;
}

.nestingCard > .ant-spin-nested-loading .ant-spin-container {
    max-height: unset;
    height: 100%;
}

.disabledInput {
    background: #f5f5f5;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    display: inline-block;
    margin-left: 4px;
    padding-inline: 4px;
    width: 160px;
}

.relatedPartsContainer {
    margin-top: 4px;
    overflow: auto;
    max-height: 400px;
    background-color: #f1f0f0;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
}
.nav-text {
    font-weight: 600;
    font-size: 13px;
}

.ant-layout-sider-collapsed {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
    flex: 0 0 60px !important;
}

.ant-menu-submenu-title {
    font-weight: 600;
    font-size: 13px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 100%;
}

.newProducerFormContainer {
    display: flex;
}

.redBoxLogo {
    background-color: #dcdcdc;
    width: min(400px, calc(100vw - 200px));
    height: min(400px, calc(100vw - 200px));
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.redBoxLogo > .ant-form-item {
    width: 100% !important;
    height: 100% !important;
    transition: all 0.3s ease-in;
}

.redBoxLogo > .ant-form-item:not(.ant-form-item-has-error) {
    position: relative;
    top: 10px;
}

.redBoxLogo .ant-form-item-control {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.redBoxLogo .ant-form-item-control-input {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.redBoxLogo .ant-form-item-control-input-content {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.logoUpload {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
}

.logoUpload > .ant-upload.ant-upload-select-picture-card {
    width: min(400px, calc(100vw - 200px)) !important;
    height: min(400px, calc(100vw - 200px)) !important;
}

.redBox {
    height: 100%;
    max-width: 500px;
}

.redBox > .ant-form-item {
    flex-direction: column;
}

.redBox > .ant-form-item > .ant-form-item-label {
    display: flex;
    justify-content: flex-start;
}

.redBox > .ant-form-item > .ant-form-item-label > .ant-form-item-required {
    height: 26px;
}

.redBox > .ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-affix-wrapper > .ant-input-prefix {
    margin-right: 8px;
}

.redBox > .ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
}

.redBoxLogo > .ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .logoUpload > .ant-upload.ant-upload-select-picture-card {
    width: auto;
    height: auto;
    margin: auto;
}
.redBoxLogo > .ant-form-item > .ant-form-item-control > .ant-form-item-explain {
    position: absolute;
    top: 258px;
    right: -148px;
    width: 400px;
}

.redBoxLogo > .ant-form-item-has-error {
    border: red 1px solid;
}

.uploadButton {
    width: 102px;
    height: 102px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redBox > .ant-upload.ant-upload-select-picture-card > .ant-upload {
    height: auto;
}

.formContainer {
    background-color: red;
    width: 100%;
    height: 400px;
}



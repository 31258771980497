.materialTableXAxisContentRight {
    position: absolute;
    top: 25px;
    right: 16px;
}

.materialTableXAxisContentLeft {
    position: absolute;
    top: 25px;
    left: 41px;
}
.nestingCard {
    padding: 6px;
    overflow: auto;
    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}

.nestingListCard {
    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
}

.arrowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
}

.arrow-left {
    position: relative;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: .8em solid transparent;
    border-bottom: .8em solid transparent;
    border-left: 1.5em solid lightgrey;
    border-radius: .1em;
}

.blueLeft {
    border-left: 1.5em solid #65b5ff;
}

.resize-h{
    width: calc(100% + 25px);
    min-width: 3em;
    height: .4em;
    background: lightgrey;
    border-radius: .05em;
    overflow: auto;
}

.blueResize {
    background: #65b5ff;
}

.dimmerWrapper > div > .ant-spin-spinning {
    margin-left: 10px;
    width: calc(100% - 20px) !important;
    background: rgba(248, 248, 248, 0.4);
    /*background: rgba(155, 155, 155, 0.1);*/
}

.totalParts {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectAllText:hover {
    cursor: pointer;
}

.dropdownOrdersWrapper {
    width: 215px;
    max-height: 240px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(205, 205, 205);
    padding: 6px 0 6px 6px;
    position: absolute;
    z-index: 2;
    right: 6px;
    top: 50px;
}

.dropdownOrders {
    overflow: auto;
    width: 208px;
    max-height: 220px;
}

.hoverPointer:hover {
    cursor: pointer;
}

.leftoverButton {
    color: #096dd9;
}

.nestingErrorTooltip {
    max-width: 600px;
}
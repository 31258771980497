

.publicWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#42576c",endColorstr="#001529",GradientType=1);
    background: white;
    /*background: rgb(41,51,61);*/
    /*background: radial-gradient(circle, rgba(41,51,61,1) 0%, rgba(0,21,41,1) 102%);*/
}

.tiledBackground {
    background: url("/src/assets/images/tiles_1.png") repeat;
    background-size: 360px 360px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.publicCard {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 450px;
    width: 500px;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    border: 1px solid #eaeaea; /*#eaeaea*/
    bottom: 4%;
    background: #ffffff;
}

.loginTitle {
    font-weight: 600;
    font-size: 2.2rem;
    color: #333f48;
}

.signInText {
    font-weight: 500;
    font-size: 1.5rem;
    color: #333f48;
}

.loginLabel {
    font-size: 13px;
    font-weight: 500;
    color: #475057;
}

.loginText {
    font-weight: 600;
}

.loginInputText {
    color: #333f48;
    font-weight: 600 !important;
}

.ant-input {
    color: #333f48;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
}

.ant-input-number-input {
    color: #333f48;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
}

.ant-form-item-explain-error > div {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.ant-form-item-explain > div {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-form-item-with-help {
    margin-bottom: 0 !important;
}

.ant-form-item-explain-error {
    max-height: 20px !important;
}

.ant-form-item-control {
    max-height: 52px;
}

.messageText {
    font-size: 14px;
    font-weight: 600;
}

:-webkit-autofill { /* -webkit also works in firefox here */
    filter:none; /* needed for firefox! */
    box-shadow: 0 0 0 100px rgba(173, 172, 172, 0.21) inset;
}


#root {
  height: 100%;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./fonts/Montserrat-SemiBold.ttf) format('opentype');
}


.switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
    font-size: 15px !important;
    font-weight: 600;
}

.inputFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 12px;
}

.inputInfoText {
    font-size: 13px !important;
    font-weight: 500;
}

.customColumn {
    flex-shrink: 1;
}

.inLine {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.inLine > .customColumn {
    width: calc(50% - 8px);
}

.inLine .customColumn:nth-child(odd) {
    margin-right: 16px;
}

.newLine {
    display: flex;
    flex-direction: column;
}

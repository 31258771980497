.registerCard {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 850px;
    min-width: 680px;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    border: 1px solid #eaeaea;
}

.ant-select-item-empty {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.ant-select-selection-search-input {
    color: #333f48;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.ant-select-selection-item {
    color: #333f48;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.countrySelect {
    display: flex;
    align-items: center;
    color: #333f48;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.ant-select-selection-placeholder {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 1.5;
}

.reCaptchaFormItem .ant-form-item-control-input {
    min-height: 85px !important;
}

.reCaptchaFormItem > .ant-form-item-control {
    max-height: 105px !important;
}

.reCaptchaFormItem.ant-form-item-has-error > .ant-form-item-control {
    height: 105px !important;
    max-height: 105px !important;
}

.reCaptchaFormItem.ant-form-item-has-success > .ant-form-item-control {
    height: 85px !important;
    max-height: 85px !important;
}

.ant-form-item-explain {
    max-height: 24px !important;
}

.regSwitch.ant-radio-group > .ant-radio-button-wrapper-checked {
    background-color: #0d2a47;
    border-color: #0d2a47;
}
.regSwitch.ant-radio-group > .ant-radio-button-wrapper-checked:hover {
    background-color: #0f3254;
    border-color: #0f3254;
}

.nameIcon {
    display: inline-block;
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 40px;
    color: white;
    font-size: 1.5em;
    margin-right: 15px;
}

.customerGridText {
    width: calc(100% - 50px);
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.customerList {
    box-sizing: border-box;
}

.customerRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0 6px 4px;
    box-sizing: border-box;
}

.customerRow:nth-of-type(1) {
    padding: 6px 0 6px 4px;
}

.customerItem {
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.customerCard {
    height: 100%;
    width: 100%;
    margin-right: 5px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 4px 1px;
    border: 1px solid rgb(207, 207, 207);
    cursor: pointer;
}

.customerCard:hover {
    border: 1px solid #1890ff !important;
}

.customerCard:hover .customerName {
    color: #1890ff;
}

.selectedCustomerCard {
    transition-duration: 0.3s;
    border: 1px solid #1890ff !important;
    background: rgba(24, 144, 255, 0.1) !important;
}

.selectedCustomerCard .customerName {
    color: #1890ff;
}

.customerCard > .ant-card-body {
    padding: 12px;
    height: 100%;
}

.fadeInCustomerCard {
    animation: fadeIn 0.3s;
    -webkit-animation: fadeIn 0.3s;
    -moz-animation: fadeIn 0.3s;
    -o-animation: fadeIn 0.3s;
    -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

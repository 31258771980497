/*.ant-table-placeholder {*/
/*    height: calc(100vh - 136px) !important;*/
/*}*/

.pendingOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content {
    min-height: calc(100vh - 157px);
}

.pendingOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content > table > .ant-table-tbody > .ant-table-placeholder {
    height: calc(100vh - 196px);
}


.pendingOrdersTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-radius: 5px;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
}

table > .ant-table-thead > tr > .ant-table-cell {
    background-color: #001529;
    color: white;
    text-align: left !important;
}

table > .ant-table-thead > tr > .ant-table-cell:first-child {
    border-radius: 5px 0 0 0 !important;
}

table > .ant-table-thead > tr > .ant-table-cell:last-child {
    border-radius: 0 5px 0 0 !important;
}

.ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
    background-color: #003362;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #003362;
}

.darkerColumn {
    background-color: #f8f8f8;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #f5f5f5;
}

td.ant-table-column-sort {
    background-color: #f5f5f5;
}

.ant-tooltip-inner {
    font-size: 12px;
    min-height: 30px;
    font-weight: 500;
}

.ant-table-filter-trigger:hover {
    color: rgba(243, 249, 255, 0.75);
    background: rgba(77, 169, 253, 0.24);
}

.ant-table-filter-dropdown .ant-dropdown-menu-item {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.ant-table-filter-dropdown-btns .ant-btn {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.column200 {
    min-width: 200px !important;
}